/** imported in
 * Order
 * components/cart-observer
*/

import {createStore} from "vuex";
import axios from "axios";

const store = createStore({
    state () {
        return {
            user: {
                address: [],
                main_company: {},
            },
            positions: [],
            total_cost: 0,
            cashback: 0,
            positions_price: 0,
            total_discount: 0,
            delivery_price: 0,
            price_without_discount: 0,
            urlPrefix: document.body.dataset.urlPrefix,
            rootProps: {},
            formData: {
                delivery_prop: {},
                pay_prop: {},
                stores: []
            },
            compare_ids: {},
            preloader: false
        }
    },
    mutations: {
        setOrder(state, payload){
            this.state.positions = []
            window.lps.order_positions = []
            window.lps.order_positions_map = {};
            state.preloader = false
            if(payload.order){
                if(payload.order.delivery_price)  {this.state.delivery_price     = payload.order.delivery_price}     else{this.state.delivery_price   = 0}
                if(payload.order.positions_price) {this.state.positions_price    = payload.order.positions_price}    else{this.state.positions_price  = 0}
                if(payload.order.total_cost)      {this.state.total_cost         = payload.order.total_cost}         else{this.state.total_cost       = 0}
                if(payload.order.total_discount)  {this.state.total_discount     = payload.order.total_discount}     else{this.state.total_discount   = 0}
                if(payload.order.price_without_discount)  {this.state.price_without_discount     = payload.order.price_without_discount}     else{this.state.price_without_discount   = 0}
                if(payload.order.cashback) {this.state.cashback = payload.order.cashback} else {this.state.cashback = 0}
                if(payload.order.positions)       {
                    this.state.positions = payload.order.positions
                    window.lps.order_positions = payload.order.positions
                    window.lps.order_positions_map = payload.order.positions.reduce((map,pos) => {
                        map[pos.entity] = pos;
                        return map
                    }, {})
                }
                window.lps.emitter.emit('updateStore', {
                    total_cost: this.state.total_cost,
                    minify: {
                        length: this.state.positions.length,
                        positions_price: this.state.positions_price,
                        total_cost: this.state.total_cost,
                        total_discount: this.state.total_discount
                    }
                })
            }
        },
        setKeys(state, payload) {
            for (let key in payload) {
                state.rootProps[key] = payload[key]
            }
        },
        setFormData(state, payload) {
            if (typeof payload !== 'object') return
            for (let key in payload) {
                state.formData[key] = payload[key]
            }
        }
    },
    actions: {
        async getData(store){
            store.state.preloader = true
            let response = await axios.get('/main/getData/')
            store.state.user = response.data.data.user;
            if (store.state.user) {
                let addrObj = []
                for (let i in store.state.user.address) {
                    addrObj.push({
                        'value': store.state.user.address[i],
                        'key': i
                    })
                }
                store.state.user.address = addrObj;
            }
            store.commit('setOrder',{order : response.data.data.order});
            store.commit('setKeys', {compare_ids: response.data.data.compare_ids})
            return response.data.data
            store.state.preloader = false
        },
        addVariantToOrder(store, payload){
            axios.post('/order/addVariantToOrder/', {
                variant_id: payload.variant_id,
                count: payload.count === null ? 1 : payload.count
            })
                .then((response) => {
                    store.commit('setOrder',{order : response.data.data.order});
                    if(HTMLElement.prototype.isPrototypeOf(payload.target)){
                        payload.target.dataset.position = response.data.data.added_position;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        delOrderPosition(store, payload){
            store.state.preloader = true
            axios.post('/order/delOrderPosition/', {
                position_id: payload.position_id
            })
                .then((response) => {
                    store.commit('setOrder',{order : response.data.data.order});
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearCart(store, payload){
            axios.post('/order/clearCart/')
                .then((response) => {
                    store.commit('setOrder',{order : response.data.data.order});
                    if(payload){
                        for(let i in payload){
                            store.dispatch('addVariantToOrder', {'variant_id': payload[i].entity, 'count': payload[i].count})
                        }
                        window.location = ('/order/');
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        changePositionCount(store, payload){
            store.state.preloader = true
            axios.post('/order/changePositionCount/', {
                position_id: payload.position_id,
                count: payload.count
            })
                .then((response) => {
                    store.commit('setOrder',{order: response.data.data.order});
                })
                .catch(error => {
                    console.log(error);
                });
        },
        async getFormData(store, payload) {
            try {
                const res = await axios.get('/order/formData/')
                store.commit('setFormData', res.data.data)
            }
            catch(err) {console.error(err)}
        },
        setRootProps(store, payload) {
            if (typeof payload !== 'object') throw new Error('payload is not an object')
            for (let key in payload) {
                if (typeof payload[key] === 'object') {
                    console.error(payload, key)
                    throw new Error('payload key is object but should be primitive')
                }
            }
            store.commit('setKeys', payload)
        },
        async postData(store, {url, formData}) {
            if (!url || store.state.preloader) return
            store.state.preloader = true
            try {
                const res = await axios.post(url, formData)
                store.state.preloader = false
                return res
            }
            catch(error) {console.error(error)}
        }
    }
});

export default store