"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return (vue_2.pushScopeId("data-v-40216f3b"), n = n(), vue_2.popScopeId(), n); };
var _hoisted_1 = { class: "order-list" };
var vuex_1 = require("vuex");
var DefaultLayout_vue_1 = require("@/components/DefaultLayout.vue");
var LoginRegister_vue_1 = require("vue_components/LoginRegister.vue");
var OrderPositionsTable_vue_1 = require("vue_components/OrderPositionsTable.vue");
exports.default = vue_1.defineComponent({
    setup: function (__props) {
        var store = vuex_1.useStore();
        return function (_ctx, _cache) {
            return (vue_2.openBlock(), vue_2.createElementBlock("div", _hoisted_1, [
                vue_2.createVNode(DefaultLayout_vue_1.default, {
                    orderEditable: vue_2.unref(store).state.positions.length ? true : false
                }, {
                    default: vue_2.withCtx(function () { return [
                        (vue_2.unref(store).state.user === null)
                            ? (vue_2.openBlock(), vue_2.createBlock(LoginRegister_vue_1.default, { key: 0 }))
                            : vue_2.createCommentVNode("", true),
                        vue_2.createVNode(OrderPositionsTable_vue_1.default, {
                            items: vue_2.unref(store).state.positions,
                            disabled: vue_2.unref(store).state.preloader,
                            onChangePositionCount: _cache[0] || (_cache[0] = function (_a) {
                                var amount = _a.amount, position_id = _a.position_id;
                                vue_2.unref(store).dispatch('changePositionCount', { position_id: position_id, count: amount });
                            }),
                            onRemovePosition: _cache[1] || (_cache[1] = function (position_id) { vue_2.unref(store).dispatch('delOrderPosition', { position_id: position_id }); })
                        }, null, 8, ["items", "disabled"])
                    ]; }),
                    _: 1
                }, 8, ["orderEditable"])
            ]));
        };
    }
});
