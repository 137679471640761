<template>
    <div class="order-page">
        <router-view />
        <div class="preloader" :class="{'a-hidden': store.state.preloader === false}"></div>
    </div>
</template>

<script setup>
import { useStore } from 'vuex'
const store = useStore()
</script>

<script>
export default {
    props: ['checkString', 'checkStringSalt'],
    created() {
        this.$store.dispatch('getData')
        this.$store.dispatch('setRootProps', {
            checkString: this.checkString,
            checkStringSalt: this.checkStringSalt
        })
    }
}
</script>

<style scoped>
.preloader {
    position: fixed;
}    
</style>