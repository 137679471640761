"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var index_vue_1 = require("./index.vue");
var router_1 = require("./router");
var store_1 = require("./store");
var appElement = document.querySelector('#v-order');
if (!appElement)
    throw 'no #v-order found on page';
var rootProps = {
    checkString: appElement.getAttribute('data-checkstring'),
    checkStringSalt: appElement.getAttribute('data-hashstring'),
};
vue_1.createApp(index_vue_1.default, rootProps)
    .use(store_1.default)
    .use(router_1.default)
    .mount(appElement);
