"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "input-container InputContainer" };
var _hoisted_2 = ["type", "placeholder", "name", "required", "value", "pattern", "title"];
var _hoisted_3 = { class: "placeholder" };
var _hoisted_4 = {
    key: 0,
    style: { "color": "#DF231A" }
};
var vue_3 = require("vue");
var helpers_1 = require("vue_components/helpers");
var imask_1 = require("imask");
exports.default = vue_1.defineComponent({
    props: {
        key: null,
        modelValue: null,
        placeholder: null,
        required: { type: Boolean },
        type: { default: 'text' },
        pattern: null,
        mask: null,
        patternTooltip: null
    },
    emits: ['update:modelValue'],
    setup: function (__props, _a) {
        var emit = _a.emit;
        var props = __props;
        function inputInput(event) {
            if (props.type === 'tel')
                imask._onInput();
            emit('update:modelValue', event.target.value);
        }
        var inputEl = vue_3.ref(null);
        var imask;
        vue_3.onMounted(function () {
            var _a;
            if (props.type === 'tel') {
                imask = imask_1.default(inputEl.value, {
                    mask: (_a = props.mask) !== null && _a !== void 0 ? _a : helpers_1.inputProperties.tel.mask
                });
            }
        });
        vue_3.onUnmounted(function () {
            if (props.type === 'tel')
                imask.destroy();
        });
        return function (_ctx, _cache) {
            return (vue_2.openBlock(), vue_2.createElementBlock("div", _hoisted_1, [
                vue_2.createElementVNode("input", {
                    ref_key: "inputEl",
                    ref: inputEl,
                    type: props.type,
                    placeholder: props.placeholder + (props.required ? ' *' : ''),
                    name: props.name,
                    required: props.required,
                    value: props.modelValue,
                    pattern: props.pattern,
                    title: props.patternTooltip,
                    onInput: inputInput
                }, null, 40, _hoisted_2),
                vue_2.createElementVNode("div", _hoisted_3, [
                    vue_2.createTextVNode(vue_2.toDisplayString(props.placeholder) + " ", 1),
                    (props.required)
                        ? (vue_2.openBlock(), vue_2.createElementBlock("span", _hoisted_4, "*"))
                        : vue_2.createCommentVNode("", true)
                ])
            ]));
        };
    }
});
