"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_3 = require("vue");
exports.default = vue_1.defineComponent({
    props: {
        marks: { default: [{
                    address: 'Moscow',
                    coords: {
                        lat: 55.76,
                        lng: 37.64
                    }
                }] },
        chosenMark: { default: 0 }
    },
    setup: function (__props) {
        var props = __props;
        var map_el = vue_3.ref(null);
        // ref не использовать!
        var map = null;
        vue_3.onMounted(function () {
            var lat = props.marks[0].coords.lat;
            var lng = props.marks[0].coords.lng;
            fetch("https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=" + YANDEX_API_KEY)
                .then(function (res) {
                if (!res.ok)
                    throw res;
                return res.text();
            })
                .then(function (code) {
                // execute fetched code
                new Function(code)();
                // reference
                // https://yandex.com/dev/maps/jsbox/2.1/placemark/
                ymaps.ready(function () {
                    // Creating the map.
                    map = new ymaps.Map(map_el.value, {
                        // The map center coordinates.
                        // Default order: “latitude, longitude”.
                        // To not manually determine the map center coordinates,
                        // use the Coordinate detection tool.
                        center: [lat, lng],
                        // Zoom level. Acceptable values:
                        // from 0 (the entire world) to 19.
                        zoom: 13
                    });
                    var query = [];
                    props.marks.forEach(function (mark) {
                        var placemark = new ymaps.Placemark([mark.coords.lat, mark.coords.lng], {
                            balloonContent: mark.address
                        }, {
                            preset: 'islands#icon',
                            iconColor: '#0095b6'
                        });
                        query.push(placemark);
                    });
                    ymaps.geoQuery(query).addToMap(map);
                });
            });
        });
        vue_3.watch(function () { return props.chosenMark; }, function (markIndex) {
        });
        return function (_ctx, _cache) {
            return (vue_2.openBlock(), vue_2.createElementBlock("div", {
                class: "Map",
                ref_key: "map_el",
                ref: map_el
            }, null, 512));
        };
    }
});
