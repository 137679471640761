"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = ["value"];
var vue_3 = require("vue");
exports.default = vue_1.defineComponent({
    props: {
        amount: { default: 1 },
        disabled: { type: Boolean }
    },
    emits: ['update'],
    setup: function (__props, _a) {
        var emit = _a.emit;
        var props = __props;
        var min = vue_3.ref(1);
        var max = vue_3.ref(1000);
        function change(val) {
            emit('update', val);
        }
        function inputInput(e) {
            if (props.disabled) {
                e.target.value = props.amount;
                return;
            }
            var v = e.target.value.replace(/\D/g, '');
            v = v === '' ? min.value : parseInt(v);
            if (v > max.value) {
                v = max.value;
            }
            if (v < min.value) {
                v = min.value;
            }
            e.target.value = v;
        }
        return function (_ctx, _cache) {
            return (vue_2.openBlock(), vue_2.createElementBlock("div", {
                class: vue_2.normalizeClass(["item-counter", { 'disabled': props.disabled }])
            }, [
                vue_2.createElementVNode("div", {
                    class: vue_2.normalizeClass(["item-counter__minus", { 'disabled': props.amount === min.value || props.disabled }]),
                    onClick: _cache[0] || (_cache[0] = function ($event) { return (change(props.amount - 1)); })
                }, null, 2),
                vue_2.createElementVNode("input", {
                    class: "item-counter__value",
                    value: props.amount,
                    onInput: inputInput,
                    onChange: _cache[1] || (_cache[1] = function ($event) { return (change(parseInt($event.target.value))); })
                }, null, 40, _hoisted_1),
                vue_2.createElementVNode("div", {
                    class: vue_2.normalizeClass(["item-counter__plus", { 'disabled': props.amount === max.value || props.disabled }]),
                    onClick: _cache[2] || (_cache[2] = function ($event) { return (change(props.amount + 1)); })
                }, null, 2)
            ], 2));
        };
    }
});
