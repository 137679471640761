"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "heading2 mt0" };
var _hoisted_2 = { class: "submission-form__inputs" };
var _hoisted_3 = { class: "submission-form__grid-2" };
var _hoisted_4 = { class: "submission-form__grid-4" };
var vue_3 = require("vue");
var InputContainer_vue_1 = require("vue_components/InputContainer.vue");
exports.default = vue_1.defineComponent({
    props: {
        title: { default: 'Адрес доставки' }
    },
    emits: ['update'],
    setup: function (__props, _a) {
        var emit = _a.emit;
        var props = __props;
        var address_inputs = vue_3.ref({
            town: {
                placeholder: 'Город',
                value: '',
            },
            street: {
                placeholder: 'Улица',
                value: '',
            },
            house: {
                placeholder: 'Дом',
                value: '',
            },
            corp: {
                placeholder: 'Корпус',
                value: '',
            },
            building: {
                placeholder: 'Строение',
                value: '',
            },
            flat: {
                placeholder: 'Офис/Квартира',
                value: '',
            }
        });
        function inputUpdate() {
            var str = '';
            var i = 0;
            for (var key in address_inputs.value) {
                if (!address_inputs.value[key].value)
                    continue;
                i++;
                str += address_inputs.value[key].value + ', ';
            }
            i > 0 ? str = str.slice(0, -2) : false;
            emit('update', str);
        }
        return function (_ctx, _cache) {
            return (vue_2.openBlock(), vue_2.createElementBlock(vue_2.Fragment, null, [
                vue_2.createElementVNode("div", _hoisted_1, vue_2.toDisplayString(props.title), 1),
                vue_2.createElementVNode("div", _hoisted_2, [
                    vue_2.createElementVNode("div", _hoisted_3, [
                        vue_2.createVNode(InputContainer_vue_1.default, {
                            name: "town",
                            placeholder: address_inputs.value.town.placeholder,
                            type: address_inputs.value.town.type,
                            required: address_inputs.value.town.required,
                            modelValue: address_inputs.value.town.value,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((address_inputs.value.town.value) = $event); }),
                            onInput: inputUpdate
                        }, null, 8, ["placeholder", "type", "required", "modelValue"]),
                        vue_2.createVNode(InputContainer_vue_1.default, {
                            name: "street",
                            placeholder: address_inputs.value.street.placeholder,
                            type: address_inputs.value.street.type,
                            required: address_inputs.value.street.required,
                            modelValue: address_inputs.value.street.value,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((address_inputs.value.street.value) = $event); }),
                            onInput: inputUpdate
                        }, null, 8, ["placeholder", "type", "required", "modelValue"])
                    ]),
                    vue_2.createElementVNode("div", _hoisted_4, [
                        vue_2.createVNode(InputContainer_vue_1.default, {
                            name: "house",
                            placeholder: address_inputs.value.house.placeholder,
                            type: address_inputs.value.house.type,
                            required: address_inputs.value.house.required,
                            modelValue: address_inputs.value.house.value,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) { return ((address_inputs.value.house.value) = $event); }),
                            onInput: inputUpdate
                        }, null, 8, ["placeholder", "type", "required", "modelValue"]),
                        vue_2.createVNode(InputContainer_vue_1.default, {
                            name: "corp",
                            placeholder: address_inputs.value.corp.placeholder,
                            type: address_inputs.value.corp.type,
                            required: address_inputs.value.corp.required,
                            modelValue: address_inputs.value.corp.value,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) { return ((address_inputs.value.corp.value) = $event); }),
                            onInput: inputUpdate
                        }, null, 8, ["placeholder", "type", "required", "modelValue"]),
                        vue_2.createVNode(InputContainer_vue_1.default, {
                            name: "building",
                            placeholder: address_inputs.value.building.placeholder,
                            type: address_inputs.value.building.type,
                            required: address_inputs.value.building.required,
                            modelValue: address_inputs.value.building.value,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) { return ((address_inputs.value.building.value) = $event); }),
                            onInput: inputUpdate
                        }, null, 8, ["placeholder", "type", "required", "modelValue"]),
                        vue_2.createVNode(InputContainer_vue_1.default, {
                            name: "flat",
                            placeholder: address_inputs.value.flat.placeholder,
                            type: address_inputs.value.flat.type,
                            required: address_inputs.value.flat.required,
                            modelValue: address_inputs.value.flat.value,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) { return ((address_inputs.value.flat.value) = $event); }),
                            onInput: inputUpdate
                        }, null, 8, ["placeholder", "type", "required", "modelValue"])
                    ])
                ])
            ], 64));
        };
    }
});
