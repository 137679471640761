"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_router_1 = require("vue-router");
var urlPrefix = document.body.dataset.urlPrefix;
var routes = [
    {
        path: urlPrefix + "/order/",
        name: "Корзина",
        component: function () { return Promise.resolve().then(function () { return require('./views/v_order_list.vue'); }); },
    },
    {
        path: urlPrefix + "/order/orderCheck/",
        name: "Оформление заказа",
        component: function () { return Promise.resolve().then(function () { return require('./views/v_order_check.vue'); }); },
    },
    {
        path: urlPrefix + "/order/orderCheck/:id/",
        name: "Заказ оформлен",
        component: function () { return Promise.resolve().then(function () { return require('./views/v_order_ended.vue'); }); },
    }
];
var router = vue_router_1.createRouter({
    history: vue_router_1.createWebHistory(),
    routes: routes,
});
exports.default = router;
