<template>
    <div class="LoginRegister">
        <div class="heading2">Войдите или зарегистрируйтесь</div>
        <div class="LoginRegister-row">
            <div class="LoginRegister-row__left">Для того что бы участвовать в акциях и получать скидки, а так же видеть индивидуальные цены. Зарегистрированный пользователь получит скидку 3% на первую покупку</div>
            <div class="LoginRegister-row__right">
                <a href="/login/" class="default-btn white-bg">Войти</a>
                <a href="/registration/" class="default-btn white">Зарегистрироваться</a>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.LoginRegister {
    color: #FFFFFF;
    background-color: #003B67;
    padding: var(--column-gap);
    border-radius: 5px;
    background-image: url('/templates/project/assets/img/ABtop-left.svg'), url('/templates/project/assets/img/ABbottom-right.svg');
    background-repeat: no-repeat;
    background-position: top left, bottom right;
    background-size: 40%;
    .heading2 {
        color: #FFFFFF;
        margin-bottom: 8px;
    }
    .LoginRegister-row {
        display: grid;
        grid-gap: 20px;
        &__right {
            display: flex;
            gap: var(--column-gutter);
        }
    }
    .default-btn.white-bg {
        min-width: unset;
        color: #000000;
        &:hover {
            color: #FFFFFF;
        }
    }
    @media (min-width: 768px) {
        .heading2 {
            margin-bottom: 20px;
        }
        .LoginRegister-row {
            display: flex;
            gap: 20px;
            align-items: center;
        }
    }
}
</style>