"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return (vue_2.pushScopeId("data-v-1abe1203"), n = n(), vue_2.popScopeId(), n); };
var _hoisted_1 = { class: "order-check" };
var DefaultLayout_vue_1 = require("@/components/DefaultLayout.vue");
var index_vue_1 = require("@/components/Check/index.vue");
var index_vue_2 = require("@/components/Welcome/index.vue");
exports.default = vue_1.defineComponent({
    setup: function (__props) {
        return function (_ctx, _cache) {
            return (vue_2.openBlock(), vue_2.createElementBlock("div", _hoisted_1, [
                vue_2.createVNode(DefaultLayout_vue_1.default, null, {
                    default: vue_2.withCtx(function () { return [
                        (_ctx.$store.state.user)
                            ? (vue_2.openBlock(), vue_2.createBlock(index_vue_1.default, { key: 0 }))
                            : (vue_2.openBlock(), vue_2.createBlock(index_vue_2.default, { key: 1 }))
                    ]; }),
                    _: 1
                })
            ]));
        };
    }
});
