"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return (vue_2.pushScopeId("data-v-4efa6da4"), n = n(), vue_2.popScopeId(), n); };
var _hoisted_1 = { class: "select__visible" };
var _hoisted_2 = { class: "select__placeholder" };
var _hoisted_3 = { class: "select__chosed" };
var _hoisted_4 = { class: "select__options scrollbar" };
var _hoisted_5 = ["onClick"];
var vue_3 = require("vue");
exports.default = vue_1.defineComponent({
    props: {
        name: null,
        icon: { default: 'termi-chevron-right' },
        defaultName: { default: 'Все' },
        chosed: { default: 0 },
        options: { default: [] },
        title: { default: 'Заголовок' },
        disabled: { type: Boolean },
        modelValue: { default: -1 }
    },
    emits: ['change', 'update:modelValue'],
    setup: function (__props, _a) {
        var emit = _a.emit;
        var props = __props;
        var localOptions = vue_3.ref([]);
        vue_3.watch(function () { return props.options; }, function (newOptions) {
            var _a;
            localOptions.value.length = 0;
            (_a = localOptions.value).push.apply(_a, __spreadArrays([{ name: props.defaultName }], newOptions));
        }, { deep: true, immediate: true });
        var chosedOption = vue_3.computed(function () {
            return localOptions.value[props.modelValue + 1];
        });
        var isToggled = vue_3.ref(false);
        function toggle(bool) {
            if (bool === undefined) {
                isToggled.value = !isToggled.value;
            }
            else {
                isToggled.value = bool;
            }
        }
        function choose(i) {
            emit('update:modelValue', i);
            emit('change', i);
        }
        return function (_ctx, _cache) {
            return (vue_2.openBlock(), vue_2.createElementBlock("div", {
                class: vue_2.normalizeClass(["select", { 'toggled': isToggled.value }]),
                onClick: _cache[0] || (_cache[0] = function ($event) { return (toggle()); }),
                tabindex: 0,
                onBlur: _cache[1] || (_cache[1] = function ($event) { return (toggle(false)); })
            }, [
                vue_2.createElementVNode("div", _hoisted_1, [
                    vue_2.createElementVNode("div", _hoisted_2, vue_2.toDisplayString(props.title), 1),
                    vue_2.createElementVNode("div", _hoisted_3, vue_2.toDisplayString(vue_2.unref(chosedOption).name), 1),
                    vue_2.createElementVNode("div", {
                        class: vue_2.normalizeClass(["select__icon", __props.icon])
                    }, null, 2)
                ]),
                vue_2.withDirectives(vue_2.createElementVNode("div", _hoisted_4, [
                    (vue_2.openBlock(true), vue_2.createElementBlock(vue_2.Fragment, null, vue_2.renderList(localOptions.value, function (option, i) {
                        return (vue_2.openBlock(), vue_2.createElementBlock("div", {
                            class: "option",
                            key: i,
                            onClick: function ($event) { return (choose(i - 1)); }
                        }, vue_2.toDisplayString(option.name), 9, _hoisted_5));
                    }), 128))
                ], 512), [
                    [vue_2.vShow, isToggled.value]
                ])
            ], 34));
        };
    }
});
