"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inputProperties = exports.formToFormData = exports.validateForm = exports.objectToUrlQuery = exports.decomposeUrlParams = exports.priceStringFormat = void 0;
// input 123123 : number
// output '123 123 ₽' : string
function priceStringFormat(number, currency) {
    if (currency === void 0) { currency = '₽'; }
    if (isNaN(number))
        number = 0;
    return new Intl.NumberFormat('ru-RU').format(number) + ' ' + currency;
}
exports.priceStringFormat = priceStringFormat;
// Limitations. Multiple params with same key
function decomposeUrlParams() {
    var result = {};
    var query = decodeURIComponent(window.location.search.substring(1));
    var params = query.split('&');
    params.forEach(function (param) {
        var _a = param.split('='), key = _a[0], value = _a[1];
        if (value === undefined)
            return;
        result[key] = value;
    });
    return result;
}
exports.decomposeUrlParams = decomposeUrlParams;
// output string
// ?param1=value1&param2=value2
function objectToUrlQuery(obj) {
    var queryArr = [];
    for (var key in obj) {
        if (obj[key] === undefined || obj[key] === '')
            continue;
        queryArr.push(key + "=" + obj[key]);
    }
    if (!queryArr.length)
        return '';
    return '?' + queryArr.join('&');
}
exports.objectToUrlQuery = objectToUrlQuery;
function validateForm(form, errors) {
    if (errors === void 0) { errors = []; }
    for (var key in form) {
        if (!form[key].value && form[key].required) {
            errors.push("\u041F\u043E\u043B\u0435: " + form[key].placeholder + " \u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u043E \u0434\u043B\u044F \u0437\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u0438\u044F");
        }
    }
    if (form.pass && form.pass2 && form.pass.value !== form.pass2.value) {
        errors.push('Пароли должны совпадать');
    }
}
exports.validateForm = validateForm;
function formToFormData(form) {
    var formData = {};
    for (var key in form) {
        formData[key] = form[key].value;
    }
    return formData;
}
exports.formToFormData = formToFormData;
// script.js inputProperties
exports.inputProperties = {
    email: {
        pattern: '^\\S+@\\S+\\.\\S+$',
        title: ''
    },
    tel: {
        pattern: '^\\+7\\(\\d\\d\\d\\)\\d\\d\\d-\\d\\d-\\d\\d$',
        mask: '+{7}(000)000-00-00',
        title: 'Телефон должен начинаться с +7 и иметь 11 цифр'
    },
    password: {
        pattern: '[a-zA-Z\\d]{6,12}',
        title: 'Ваш пароль должен состоять из 6-12 символов и содержать сочетание букв и хотя бы одну цифру (символы *, @, #, ?, $, %, и т. д. не допускается).'
    }
};
